body, .App {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
  font-family: Arial, sans-serif;
  background: linear-gradient(to bottom, #fff4e0, #ffe8d6, #f6e6e0); 
  color: #333;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@500&display=swap');

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  width: 65px;
  height: auto;
  margin-right: 8px;
}

.logo-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: #333;
  text-transform: none; 
}


.navbar a {
  text-decoration: none;
  color: inherit;
}

/* Navbar Section */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.navbar-logo {
  font-size: 1.8em;
  font-weight: 600;
  color: #333;
  letter-spacing: 1px;
  text-transform: uppercase;
}


.navbar-links {
  display: flex;
  gap: 25px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-links li {
  color: #333;
  font-size: 1em;
  padding: 8px 15px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease, transform 0.2s ease;
}

.navbar-links li:hover {
  color: #000;
  transform: scale(1.05);
}


.navbar-links li::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navbar-links li:hover::after {
  width: 100%;
}


.navbar-actions {
  display: flex;
  gap: 15px;
}

.navbar-sign-in {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 0.95em;
  padding: 8px 15px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;
}

.navbar-sign-in:hover {
  color: #fff;
  background-color: #333; 
}


.navbar-cta {
  background-color: #1c1c1e;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.95em;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.navbar-cta:hover {
  background-color: #333;
  transform: translateY(-3px); 
}


.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  color: #222;
  padding: 10px 0;
  list-style: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  border-radius: 8px;
  z-index: 1;
  animation: fadeIn 0.3s ease forwards;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 10px 20px;
  white-space: nowrap;
  font-size: 0.9em;
  transition: background-color 0.3s ease, padding-left 0.2s ease;
}

.dropdown-menu li:hover {
  background-color: #f8f8f8;
  color: #333;
  padding-left: 25px; 
}


.hamburger {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
}

@media (max-width: 768px) {
 
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 0;
    gap: 15px;
    z-index: 999;
    border-top: 1px solid #e0e0e0;
    animation: slideInUp 0.3s ease forwards;
  }
  
  .navbar-links.open {
    display: flex;
  }
  
  .hamburger {
    display: block;
  }

  .navbar-actions {
    display: none; 
  }

  .navbar-links li {
    padding: 10px 20px;
    font-size: 1em;
  }

  
  .dropdown-menu {
    position: relative; 
    padding-left: 15px;
    box-shadow: none;
    margin-top: 10px;
  }

  .dropdown-menu li {
    padding-left: 25px;  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Hero Section  */
.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 80px 20px;
  background: 
    radial-gradient(circle at top left, #a6cff1, transparent 50%), 
    radial-gradient(circle at bottom right, #ffe7d4, transparent 40%), 
    linear-gradient(to bottom, #ffe7d4, #ffe7d4, #ffe7d4);
  animation: fadeIn 1s ease-in-out; 
}

.hero-heading {
  font-size: 3em;
  font-weight: bold;
  color: #030101;
  margin-bottom: 20px;
  max-width: 800px;
  animation: slideInLeft 1s ease-in-out; 
}

.hero-paragraph {
  font-size: 1.25em;
  color: #555;
  max-width: 700px;
  line-height: 1.6;
  margin-bottom: 30px;
  animation: slideInRight 1s ease-in-out; 
}

.hero-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  animation: slideInUp 1s ease-in-out;
}

.hero-button-primary,
.hero-button-secondary {
  padding: 12px 25px;
  border-radius: 5px;
  font-size: 1em;
  transition: all 0.3s ease;
}

.hero-button-primary {
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
}

.hero-button-primary:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  transform: scale(1.05); 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.hero-button-secondary {
  border: 1px solid #333;
  color: #000;
  cursor: pointer;
}

.hero-button-secondary:hover {
  background-color: #000;
  color: #fff;
  transform: scale(1.05); 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}


.hero-image {
  width: 100%;
  max-width: 500px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-color: rgb(250, 245, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in-out 0.5s; }

.sliding-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  opacity: 1;
  transition: opacity 0.7s ease-in-out;
}


@media (max-width: 768px) {
  .hero-section {
    padding: 40px 15px;
  }

  .hero-heading {
    font-size: 1.8em;
    max-width: 90%;
    margin: 0 auto 15px auto;
    animation: slideInLeft 1s ease-in-out;
  }

  .hero-paragraph {
    font-size: 1em;
    max-width: 90%;
    line-height: 1.5;
    margin: 0 auto 20px auto;
    animation: slideInRight 1s ease-in-out;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    animation: slideInUp 1s ease-in-out;
  }

  .hero-button-primary,
  .hero-button-secondary {
    display: inline-flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 1em;
    width: 100%;
    justify-content: center;
  }

  .hero-image {
    max-width: 100%;
    margin: 20px auto;
    padding: 0;
    animation: fadeIn 1s ease-in-out 0.5s;
  }

  .sliding-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 250px;
  }
}



@media (max-width: 768px) {
  .hero-section {
    padding: 40px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .hero-heading {
    font-size: 1.8em;
    max-width: 90%;
    margin: 0 auto 15px auto; 
  }

  .hero-paragraph {
    font-size: 1em;
    max-width: 90%;
    line-height: 1.5;
    margin: 0 auto 20px auto; 
  }

  .hero-buttons {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;  }

  .hero-button-primary,
  .hero-button-secondary {
    display: inline-flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 1em;
    width: 100%;
    justify-content: center;
  }

 
  .hero-image {
    max-width: 100%;
    margin: 20px auto; 
    padding: 0;
  }

  .sliding-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 250px; 
  }
}


/* Features Section */
.features-section {
  display: flex;
  flex-direction: column; 
  gap: 40px;
  padding: 50px 20px;
  background: 
    radial-gradient(circle at top left,  #ffe7d4, transparent 100%), 
    radial-gradient(circle at bottom right,  #ffe7d4, transparent 80%), 
    linear-gradient(to bottom, #ffe7d4, #fff5f0, #ffe7d4);
  box-sizing: border-box;
}

.features-group {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  gap: 20px;
}


.features-section h1 {
  font-size: 1.5em;
  margin: 20px 0 10px;
  text-align: center;
  color: #333;
}

.feature-item {
  background-color: #ffe5e1;
  padding: 30px;
  border-radius: 12px;
  border: 1px solid #ffd9d4;
  width: calc(25% - 20px); 
  max-width: 250px;
  text-align: left;
  opacity: 0; 
  transform: translateY(20px); 
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, box-shadow 0.3s, transform 0.3s;
  transform-origin: center;
}

.slide-in {
  opacity: 1;
  transform: translateY(0); 
}


.feature-item:hover {
  transform: scale(1.05); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}


.delay-1 { transition-delay: 0.2s; }
.delay-2 { transition-delay: 0.4s; }
.delay-3 { transition-delay: 0.6s; }
.delay-4 { transition-delay: 0.8s; }
.delay-5 { transition-delay: 1s; }
.delay-6 { transition-delay: 1.2s; }
.delay-7 { transition-delay: 1.4s; }

/* Icon Styling */
.feature-icon {
  font-size: 1.8em;
  color: #ff7f50;
  margin-bottom: 15px;
  display: block;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .feature-item {
    width: calc(50% - 20px); 
  }
}

@media (max-width: 768px) {
  .features-group {
    overflow-x: auto; 
  }
  .feature-item {
    width: 80%; 
    max-width: 100%;
    flex: 0 0 auto;
  }
}
